import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { Reducer, combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import commonSlice from './features/common.slice';

import {
  analyticsPersistConfig,
  authPersistConfig,
  commonPersistConfig,
  dashboardPersistConfig,
  emailPersistConfig,
  notificationsPersistConfig,
  planPersistConfig,
  sessionPersistConfig,
  settingsPersistConfig,
  shipmentPersistConfig,
  smsPersistConfig,
  trackingPagePersistConfig,
} from './persist.config';
import { apiCaller } from './api/api.caller';
import dashboardPageSlice from './features/dashboard.slice';
import reviewTableSlice from './features/reviewTable.slice';
import shipmentSlice from './features/shipment.slice';
import trackingPageSlice from './features/trackingPage.slice';
import trackingLogsSlice from './features/trackingLogs.slice';
import planSlice from './features/plan.slice';
import smsSlice from './features/sms.slice';
import authSlice from './features/auth.slice';
import emailSlice from './features/email.slice';
import settingsSlice from './features/settings.slice';
import { analyticsSlice } from './features/analytics.slice';
import { notificationsSlice } from './features/notifications.slice';
import { sessionSlice } from '@/redux/features/session.slice';

const rtkQueryMiddleware = [apiCaller.middleware];

const rootReducer = combineReducers({
  // Thêm Reducer tại đây. Sample : sample : sampleReducer
  common: persistReducer(commonPersistConfig, commonSlice.reducer),
  dashboard: persistReducer(dashboardPersistConfig, dashboardPageSlice.reducer),
  reviewTable: reviewTableSlice.reducer,
  shipment: persistReducer(shipmentPersistConfig, shipmentSlice.reducer),
  trackingPage: persistReducer(trackingPagePersistConfig, trackingPageSlice.reducer),
  trackingLogs: trackingLogsSlice.reducer,
  plan: persistReducer(planPersistConfig, planSlice.reducer),
  email: persistReducer(emailPersistConfig, emailSlice.reducer),
  sms: persistReducer(smsPersistConfig, smsSlice.reducer),
  auth: persistReducer(authPersistConfig, authSlice.reducer),
  settings: persistReducer(settingsPersistConfig, settingsSlice.reducer),
  analytics: persistReducer(analyticsPersistConfig, analyticsSlice.reducer),
  notifications: persistReducer(notificationsPersistConfig, notificationsSlice.reducer),
  apiCaller: apiCaller.reducer,
  session: persistReducer(sessionPersistConfig, sessionSlice.reducer),
});

const store = configureStore({
  reducer: rootReducer as Reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(rtkQueryMiddleware),
});

// setupListeners(store.dispatch)
export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const persistor = persistStore(store);
export default store;
