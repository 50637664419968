import { PersistConfig } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { ICommonState } from './features/common.slice';
import { ITrackingPageState } from './features/trackingPage.slice';
import { IShipmentState } from './features/shipment.slice';
import { IPlanState } from './features/plan.slice';
import { IAuthState } from './features/auth.slice';
import { ISettingsState } from './features/settings.slice';
import { IEmailState } from './features/email.slice';
import { ISmsState } from './features/sms.slice';
import { IAnalyticsState } from './features/analytics.slice';
import { INotificationsState } from './features/notifications.slice';
import { IDashboardState } from './features/dashboard.slice';
import storageSession from 'redux-persist/lib/storage/session';

export const commonPersistConfig: PersistConfig<ICommonState> = {
  key: 'common',
  storage,
  whitelist: ['haveClosedPopup'],
};

export const trackingPagePersistConfig: PersistConfig<ITrackingPageState> = {
  key: 'trackingPage',
  storage,
  whitelist: ['tabSelected', 'tabConfigurationSelected'],
};

export const shipmentPersistConfig: PersistConfig<IShipmentState> = {
  key: 'shipment',
  storage,
  whitelist: ['disabledBtn'],
};

export const settingsPersistConfig: PersistConfig<ISettingsState> = {
  key: 'settings',
  storage,
  whitelist: ['tabSelected'],
};

export const planPersistConfig: PersistConfig<IPlanState> = {
  key: 'plan',
  storage,
};

export const authPersistConfig: PersistConfig<IAuthState> = {
  key: 'auth',
  storage,
};

export const emailPersistConfig: PersistConfig<IEmailState> = {
  key: 'email',
  storage,
  whitelist: ['orderStatus', 'haveShownVerifyEmailPopup', 'haveClosedBanner', 'emailTab', 'emailTemplatesTab'],
};

export const smsPersistConfig: PersistConfig<ISmsState> = {
  key: 'sms',
  storage,
  whitelist: ['tabSelected', 'orderStatusTabSelected'],
};

export const analyticsPersistConfig: PersistConfig<IAnalyticsState> = {
  key: 'analytics',
  storage,
  whitelist: ['selectedTab'],
};

export const notificationsPersistConfig: PersistConfig<INotificationsState> = {
  key: 'notifications',
  storage,
  whitelist: ['selectedTab'],
};

export const dashboardPersistConfig: PersistConfig<IDashboardState> = {
  key: 'dashboard',
  storage,
  blacklist: ['titleDatePicker', 'datePicker'],
};

export const sessionPersistConfig = {
  key: 'session',
  storage: storageSession,
};
