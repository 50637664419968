import { isShowHolidayBannerSelector, sessionSlice } from '@/redux/features/session.slice';
import { Banner } from '@shopify/polaris';
import { ReactNode } from 'react';
import { useDispatch, useSelector } from 'react-redux';

interface IProps {
  children: ReactNode;
  title: string;
}

export default function HolidayBanner(props: IProps) {
  const dispatch = useDispatch();
  const isShowHolidayBanner = useSelector(isShowHolidayBannerSelector);

  const handleHideBanner = () => {
    dispatch(sessionSlice.actions.handleIsShowHolidayBanner(false));
  };

  return isShowHolidayBanner ? (
    <Banner title={props.title} onDismiss={handleHideBanner}>
      {props.children}
    </Banner>
  ) : null;
}
